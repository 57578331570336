








































import Assets from '@/definitions/assets'

export default {
  name: 'TopNavigation',
  computed: {
    logoWhite() { return Assets.logoWhite },
  },
}
