


































export default {
  name: 'BottomNavigation',
}
