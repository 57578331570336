










import BottomNavigation from '@/components/Navigations/BottomNavigation.vue'
import TopNavigation from '@/components/Navigations/TopNavigation.vue'

export default {
  name: 'InsideContainer',
  components: {
    BottomNavigation,
    TopNavigation,
  },
}
